<template>
  <div class="page">
    <el-row>
      <el-col :span="12" :xs="{span:24,offset:0}" :offset="6">
        <div class="formBox">
          <el-row>
            <el-col :span="11">
              <div class="codeImg hidden-xs-only"></div>
            </el-col>
            <el-col :span="13" :xs="{span:21,offset:0}" :offset="0">
              <el-row>
                <el-col :span="20" :offset="2" :xs="{span:22}">
                  <div class="loginTitle">欢迎登录</div>
                </el-col>
              </el-row>
              <el-form
                hide-required-asterisk
                :model="form"
                :rules="rules"
                ref="form"
                label-width="100px"
                class="demo-form formContent"
                @validate="formValidate"
                @submit.native.prevent
              >
                <el-row>
                  <el-col :span="21" :xs="{span:22}">
                    <el-form-item label="邮箱" prop="email">
                      <el-input
                        aria-autocomplete="none"
                        aria-label="邮箱"
                        clearable
                        placeholder="请输入注册邮箱"
                        type="text"
                        v-model="form.email"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row class="margin-t10">
                  <el-col :span="21" :xs="{span:22}">
                    <el-form-item label="密码" prop="password">
                      <el-input
                        aria-autocomplete="none"
                        aria-label="密码"
                        clearable
                        v-model="form.password"
                        type="password"
                        placeholder="请输入密码"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row class="margin-t10">
                  <el-col :span="14" :xs="{span:14,offset:0}">
                    <el-form-item label="图片验证码" prop="imgCaptcha">
                      <el-input
                        aria-autocomplete="none"
                        aria-label="图片验证码"
                        style="ime-mode: disabled;"
                        maxlength="4"
                        aria-valuemax="4"
                        onpaste="return false"
                        ondragenter="return false"
                        clearable
                        placeholder="请输入图片中的验证码"
                        v-model="form.imgCaptcha"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6" :offset="1" :xs="{span:7,offset:1}">
                    <img class="verifiImg" :src="imgKeyData.img" alt="图片验证码" />
                    <div class="refresh cursor" @click="refreshCode()">点击刷新</div>
                  </el-col>
                </el-row>

                <el-row class="margin-t10">
                  <el-col :span="21" :xs="{span:22}">
                    <el-form-item class="margin0">
                      <el-button
                        type="primary"
                        native-type="submit"
                        class="loginButton"
                        :class="{btnDisabled:Object.keys(formValid).length != (Object.keys(rules).length)}"
                        @click="submitForm"
                      >登录</el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="21" :xs="{span:22}">
                    <el-form-item class="margin0">
                      <div class="flex space-between">
                        <a class="href" href="/account/password/reback">找回密码</a>
                        <a class="href" href="/account/register">立即注册</a>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { passWord } from "@/utils/RegExp.js";
import { singleAccountSever } from "@/common/api-config";

export default {
  data() {
    //验证码检验
    var checkImgCaptcha = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入图形中的验证码"));
      } else if (value.length == 4) {
        singleAccountSever
          .checkImgCaptcha({
            imgCaptcha: value,
            ckey: this.form.ckey,
          })
          .then((res) => {
            if (res.code == 0) {
              callback();
            } else if (res.code == 1019) {
              // 图片验证码过期，需要重新获取图片验证码
              this.refreshCode();
              callback(new Error("图形验证码已失效"));
            } else {
              callback(new Error("图形验证码输入错误"));
            }
          });
      } else {
        callback(new Error("图形验证码输入错误"));
      }
    };
    return {
      form: {
        email: "",
        imgCaptcha: "",
        ckey: "",
        password: "",
      },
      formValid: {},
      imgKeyData: {},
      rules: {
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, validator: passWord, trigger: ["blur", "change"] },
        ],
        imgCaptcha: [
          {
            required: true,
            validator: checkImgCaptcha,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  mounted() {
    this.getImgCaptcha();
  },
  methods: {
    //检查每个input是否通过
    formValidate(key, valid) {
      if (valid) {
        this.$set(this.formValid, key, valid);
      } else {
        this.$delete(this.formValid, key);
      }
    },
    //提交
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formData = utils.deepClone(this.form);
          formData.password = this.$md5(
            this.form.password + this.configBaseSALT //密码加盐
          );
          singleAccountSever.login(formData).then((result) => {
            if (result.code == 0) {
              localStorage.setItem("Token", result.data.token);
              this.$store
                .dispatch("accountStore/updateAccountInfo", {})
                .then((res) => {
                  this.$router.push({
                    path: this.$route.query.redirect
                      ? this.$route.query.redirect
                      : "/",
                  });
                });
              this.$store
                .dispatch("accountStore/updateUserData", {})
                .then((res) => {});
            } else {
              this.$message.error(result.message);
              // 登录错误，需要重新获取图片验证码
              this.refreshCode();
            }
          });
        } else {
          return false;
        }
      });
    },
    //刷新图标验证码
    refreshCode() {
      this.getImgCaptcha();
    },
    //拿图片验证码数据
    getImgCaptcha() {
      singleAccountSever.getImgCaptcha().then((result) => {
        if (result.code == 0) {
          this.imgKeyData = result.data[0].captcha;
          this.form.ckey = this.imgKeyData.key;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-form-item__error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 16px;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.page {
  background-color: #f8f6f7;
  padding: 60px 0px 70px 0px;
  height: 100%;

  .formBox {
    height: 450px;
    background-color: #ffffff;
    border-radius: 10px;

    .codeImg {
      background: url("~@/assets/img/account/loginImg.png") center;
      width: 100%;
      height: 451px;
      border-radius: 10px 0px 0px 10px;
      display: block;
      background-size: cover;
    }

    .leftImg {
      width: 100%;
    }

    .loginTitle {
      margin: 30px auto;
      text-align: center;
      font-size: 23px;
      font-weight: bold;
      color: rgba(18, 18, 18, 1);
      line-height: 60px;
    }

    .formContent {
      .verifiImg {
        display: block;
        width: 100%;
        height: 36px;
        min-height: 36px;
        border-radius: 4px;
        margin: auto;
      }

      .refresh {
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 30px;

        &:hover {
          text-decoration: underline;
        }
      }

      .verifiCode {
        display: block;
        cursor: pointer;
        margin: 7px auto 0px auto;
        height: 19px;
        width: 19px;
        background: url("~@/assets/img/account/refresh.png") no-repeat;
        background-size: 100% 100%;
      }

      .loginButton {
        width: 100%;
        height: 50px;
        font-size: 18px;
        cursor: pointer;
      }

      .btnDisabled {
        opacity: 0.25;
        cursor: not-allowed;
      }
    }

    .another {
      color: dodgerblue;
    }

    .serviceDec {
      color: dodgerblue;
    }
  }
}

@media screen and (max-width: 767px) {
  .page {
    padding: 0px 0px 70px 0px;
  }
}
</style>